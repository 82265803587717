import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

import { LoadingSpinner } from '@/components/ui/loading/spinner';
import { UserMessage } from '@/components/ui/user-message';
import { useSubscriptionPlans } from '@/hooks/use-subscription-plans';
import { media } from '@/utils/media';
import { getPlanPrice, getPlanPriceString } from '@/utils/subscriptions';
/**
 * PlanListingExtended
 *
 * Note: This listing is hardcoded until we figure out a nice way to sync
 * this with either Stripe or our own Database. DatoCMS is probably not
 * the right place for this anymore now that our source of truth is connected
 * to user Entitlements
 */

const PlanListingExtended = () => {
  const { isLoading, plans, error } = useSubscriptionPlans();

  if (error) return <UserMessage variant="error" heading={error} />;
  if (isLoading || !plans.length) return <LoadingSpinner isVisible />;

  const [starterPlan, basicPlan, teamPlan, proPlan] = plans;

  // Plan Prices
  const teamPlanPrice = getPlanPrice(teamPlan);
  const basicPlanPrice = getPlanPrice(basicPlan);
  const proPlanPrice = getPlanPrice(proPlan);

  return (
    <Overflow>
      <Table>
        <thead>
          <Tr>
            <Th>&nbsp;</Th>
            <Th>{starterPlan.name}</Th>
            <Th>{basicPlan.name}</Th>
            <Th>{teamPlan.name}</Th>
            <Th>{proPlan.name}</Th>
          </Tr>
        </thead>
        <tbody>
          {teamPlanPrice && proPlanPrice && basicPlanPrice ? (
            <Tr>
              <Td>Cost per month</Td>
              <Td>Free</Td>
              <Td>
                {getPlanPriceString(basicPlanPrice)} / {basicPlanPrice.interval}
              </Td>
              <Td>
                {getPlanPriceString(teamPlanPrice)} / {teamPlanPrice.interval}
              </Td>
              <Td>
                {getPlanPriceString(proPlanPrice)} / {proPlanPrice.interval}
              </Td>
            </Tr>
          ) : null}
          <Tr>
            <Td>
              <Category>Usage</Category>
            </Td>
            <Td />
            <Td />
            <Td />
            <Td />
          </Tr>
          <Tr>
            <Td>User accounts</Td>
            <Td>1</Td>
            <Td>1</Td>
            <Td>Up to 3</Td>
            <Td>Up to 8</Td>
          </Tr>
          <Tr>
            <Td>Route searches</Td>
            <Td>10 / month</Td>
            <Td>30 / month</Td>
            <Td>300 / month</Td>
            <Td>1200 / month</Td>
          </Tr>
          <Tr>
            <Td>Schedule searches</Td>
            <Td>5 / month</Td>
            <Td>15 / month</Td>
            <Td>150 / month</Td>
            <Td>600 / month</Td>
          </Tr>
          <Tr>
            <Td>
              <Category>Research tools & Content</Category>
            </Td>
            <Td />
            <Td />
            <Td />
            <Td />
          </Tr>
          <Tr>
            <Td>Airport and Seaport data</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Carrier/Operator information</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Port Insights</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Congestion & delay data</Td>
            <Td />
            <Td />
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>
              <Category>Route Searching</Category>
            </Td>
            <Td />
            <Td />
            <Td />
            <Td />
          </Tr>
          <Tr>
            <Td>A to B route search</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Routes paths on interactive map</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Alternative routes and carriers</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Detailed route itineraries</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Carrier departure frequency</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Live schedule search</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>CO2 emission estimates</Td>
            <Td />
            <Td>✓</Td>
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Advanced routing search</Td>
            <Td />
            <Td />
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Route Via / Avoid ports</Td>
            <Td />
            <Td />
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Search History</Td>
            <Td />
            <Td />
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>
              <Category>Support</Category>
            </Td>
            <Td />
            <Td />
            <Td />
            <Td />
          </Tr>
          <Tr>
            <Td>General Support</Td>
            <Td />
            <Td />
            <Td>✓</Td>
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td>Priority Support</Td>
            <Td />
            <Td />
            <Td />
            <Td>✓</Td>
          </Tr>
          <Tr>
            <Td />
            <Td />
            <Td />
            <Td />
            <Td />
          </Tr>
        </tbody>
      </Table>
    </Overflow>
  );
};

const Overflow = styled.div`
  ${media.untilMd} {
    overflow: auto;
    margin: 0 -${themeGet('space.base')};
    padding-left: ${themeGet('space.base')};
  }
`;

const Table = styled.table`
  min-width: 650px;
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  & > :first-child {
    background: transparent;
    width: 34%;
    text-align: left;
    border-top: none;
  }

  &:last-child {
    td {
      padding: ${themeGet('space.base')} ${themeGet('space.3')};
    }

    & > td:first-child {
      border-bottom: none;
    }
  }
`;

const Th = styled.th`
  font-size: ${themeGet('fontSizes.md')};
  font-weight: ${themeGet('fontWeights.medium')};
  border-top: 1px solid ${themeGet('colors.grey.200')};
  border-bottom: 1px solid ${themeGet('colors.grey.200')};
  border-right: 1px solid ${themeGet('colors.grey.200')};
  padding: ${themeGet('space.3')} 0;
  background: white;
  text-align: center;
`;

const Td = styled.td`
  font-size: ${themeGet('fontSizes.base')};
  border-bottom: 1px solid ${themeGet('colors.grey.200')};
  border-right: 1px solid ${themeGet('colors.grey.200')};
  background: white;
  width: 16%;
  text-align: center;
  padding: ${themeGet('space.sm')} 0;
`;

const Category = styled.span`
  display: block;
  color: ${themeGet('colors.lightBlue.600')};
  font-size: ${themeGet('fontSizes.sm')};
  font-weight: ${themeGet('fontWeights.medium')};
  text-transform: uppercase;
  padding-top: ${themeGet('space.base')};
`;

export { PlanListingExtended };
