import { SHIPMENT_REFERENCE_TYPES } from '@/lib/constants';
import { ShipmentsImportRowData } from '@/lib/models/shipment/types';

export const SHIPMENT_IMPORT_TABLE_COLUMNS = {
  CONTAINER_ID: 'containerId',
  AWB: 'awbNumber',
  CARRIER: 'carrier',
  TAGS: 'tags',
} as const;

export const SHARED_DEFAULT_VALUES: ShipmentsImportRowData = {
  [SHIPMENT_IMPORT_TABLE_COLUMNS.TAGS]: '',
};

export const AIR_DEFAULT_VALUES: ShipmentsImportRowData = {
  [SHIPMENT_REFERENCE_TYPES.AWB_NUMBER]: '',
  ...SHARED_DEFAULT_VALUES,
};

export const CONTAINERS_DEFAULT_VALUES: ShipmentsImportRowData = {
  [SHIPMENT_REFERENCE_TYPES.CONTAINER_NUMBER]: '',
  ...SHARED_DEFAULT_VALUES,
};

export const ROWS_FIELD_ARRAY_NAME = 'rows';

export const MAX_IMPORT_ROWS = 100;

export const POTENTIAL_REQUIRED_FIELDS = [
  SHIPMENT_REFERENCE_TYPES.CONTAINER_NUMBER,
  SHIPMENT_REFERENCE_TYPES.AWB_NUMBER,
];

export const SHIPMENT_IMPORT_ERROR_MESSAGES = {
  USAGE_LIMIT_REACHED:
    'You have reached the limit of how many shipments you can import, remove some items or <a href="/account/organisation/billing">upgrade your plan</a',
};

export const SHIPMENT_IMPORT_VALIDATION_MESSAGES = {
  AWB_NUMBER: 'Invalid AWB number. It should be 11 digits long and contain only numbers. Remove any spaces or hyphens',

  CONTAINER_NUMBER: 'Invalid container ID. Ensure it follows the correct format.',
};
