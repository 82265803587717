'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isFunction } from 'lodash';
import { useEffect } from 'react';
import { Controller, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/button';
import { TextInput } from '@/components/inputs';
import { SHIPMENT_IMPORT_VALIDATION_MESSAGES } from '@/components/modules/shipment/import/constants';
import { Select } from '@/components/ui/inputs/select';
import { UserMessage } from '@/components/ui/user-message';
import {
  API_ERROR,
  MODE_AIR,
  MODE_INTERMODAL,
  SHIPMENT_FORM_FIELDS,
  SHIPMENT_REFERENCE_TYPES,
  TRACKING_TYPE,
} from '@/lib/constants';
import { CreateShipmentBody, ShipmentType } from '@/lib/models/shipment/types';
import { OptionGroup, ShipmentFormInputsInterface } from '@/types';
import { validateAwbNumber } from '@/utils/shipments/validators/awb';
import { validateContainerId } from '@/utils/shipments/validators/container';
import { validateMbl } from '@/utils/shipments/validators/mbl';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

export interface ShipmentFormProps {
  onSubmit: (data: CreateShipmentBody) => Promise<void>;
  errors?: FieldErrors<ShipmentFormInputsInterface>;
  onError?: (hasError: boolean) => void;
  variant?: 'inline';
  buttonText?: string;
  disableButtonUntilValid?: boolean;
  buttonIcon?: string;
  className?: string;
}

const validationSchema = yup
  .object({
    [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE]: yup.string().required('Tracking number type is required'),
    [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER]: yup.string().when(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE, {
      is: SHIPMENT_REFERENCE_TYPES.CONTAINER_NUMBER,
      then: yup
        .string()
        .test('is-valid-container-id', SHIPMENT_IMPORT_VALIDATION_MESSAGES.CONTAINER_NUMBER, (value) =>
          validateContainerId(value),
        ),
      otherwise: yup.string().when(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE, {
        is: SHIPMENT_REFERENCE_TYPES.AWB_NUMBER,
        then: yup
          .string()
          .test('is-valid-awb', SHIPMENT_IMPORT_VALIDATION_MESSAGES.AWB_NUMBER, (value) => validateAwbNumber(value)),
        otherwise: yup.string().required('Tracking number is required'),
      }),
    }),
  })
  .required();

const SHIPMENT_TYPE_OPTIONS: OptionGroup[] = [
  {
    title: '',
    items: [
      {
        value: SHIPMENT_REFERENCE_TYPES.CONTAINER_NUMBER,
        label: 'Container ID',
      },
      {
        value: 'mblNumber',
        label: TRACKING_TYPE.MBOL,
      },
      {
        value: SHIPMENT_REFERENCE_TYPES.AWB_NUMBER,
        label: TRACKING_TYPE.AWB,
      },
    ],
  },
];

const VALIDATION_MESSAGES: { [key: string]: { message: string; validator: (value: string) => boolean } } = {
  [SHIPMENT_REFERENCE_TYPES.MBL_NUMBER]: {
    message:
      "The given MBOL number doesn't appear to match any known formats and might not be valid. Double check it before submitting your tracking request.",
    validator: validateMbl,
  },
};

export const getUserMessage = (error: string): string => {
  switch (error) {
    case API_ERROR.QUOTA_EXCEEDED:
      return 'You have reached the limit of how many shipments you can add on your plan.';
    case API_ERROR.UNAUTHORISED:
      return 'You appear to not be signed in. Please refresh the page and try logging in.';
    case API_ERROR.TRACKING_REQUEST_EXISTS:
      return 'A new or pending tracking request already exists for this tracking number.';
    default:
      return 'Something went wrong, please check the details and try again.';
  }
};

const getTrackingCodeHint = (type: string): string => {
  switch (type) {
    case 'containerNumber':
      return 'This is the Carrier Code + the 7 digit Container Number';
    case 'mblNumber':
      return 'This is the Master Bill of Lading provided by the carrier';
    case 'awbNumber':
      return 'This is the Airway Bill Number provided by the airline';
    default:
      return '';
  }
};

const getPlaceholderText = (type: string): string => {
  switch (type) {
    case SHIPMENT_REFERENCE_TYPES.CONTAINER_NUMBER:
      return 'e.g. MSDU1234567';
    case SHIPMENT_REFERENCE_TYPES.MBL_NUMBER:
      return 'e.g. 123456789';
    case SHIPMENT_REFERENCE_TYPES.AWB_NUMBER:
      return 'e.g. 12345678905';
    default:
      return '';
  }
};

const ShipmentForm = ({
  onSubmit,
  errors: errorsProp,
  onError,
  variant,
  buttonText = 'Submit',
  disableButtonUntilValid,
  buttonIcon,
  className,
}: ShipmentFormProps) => {
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ShipmentFormInputsInterface>({
    resolver: yupResolver(validationSchema),
    errors: errorsProp,
  });

  const referenceNumberHasAValue = getValues(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER);

  const handleShipmentFormSubmit: SubmitHandler<ShipmentFormInputsInterface> = async (data) => {
    if (data) {
      const { referenceNumberType, referenceNumber } = data;
      let shipmentType: ShipmentType = MODE_INTERMODAL;
      if (referenceNumberType === 'awbNumber') {
        shipmentType = MODE_AIR;
      }
      const shipment: CreateShipmentBody = { type: shipmentType, [referenceNumberType]: referenceNumber };

      trackUser.event('Submit shipment form', { shipment });
      try {
        await onSubmit(shipment);
        // Reset if no errors were returned
        reset({
          [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER]: '',
          [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE]: referenceNumberType,
        });
      } catch (e: any) {
        setError('root.serverError', { message: getUserMessage(e.message) });
      }
    }
  };

  useEffect(() => {
    if (isFunction(onError)) {
      onError(!isEmpty(errors));
    }
  }, [errors, onError]);

  const shipmentReferenceType = watch(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE);
  const shipmentReferenceValue = watch(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER);
  const trackingNumberHintMessage = getTrackingCodeHint(shipmentReferenceType);
  const placeholderText = getPlaceholderText(shipmentReferenceType);

  const validationMessage = (() => {
    if (!referenceNumberHasAValue) return null;

    const { message, validator } = VALIDATION_MESSAGES[shipmentReferenceType] || {};
    if (validator && message) {
      return !validator(shipmentReferenceValue) ? message : null;
    }
    return null;
  })();

  const { base, inputWrapper, buttonWrapper, button, error, typeSelect } = styles({ variant });

  return (
    <>
      {errors.root?.serverError ? (
        <UserMessage body={errors.root.serverError.message} heading="Error" variant="error" className={error()} />
      ) : null}
      <form className={base({ className })} onSubmit={handleSubmit(handleShipmentFormSubmit)}>
        <Controller
          control={control}
          name={SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE}
          defaultValue=""
          render={({ field: { onChange, value, ...field } }) => (
            <Select
              className={typeSelect()}
              value={value}
              id={field.name}
              errors={errors}
              label="Tracking number type"
              onSelect={(option) => {
                onChange(option.value);
                clearErrors(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER);
              }}
              options={SHIPMENT_TYPE_OPTIONS}
              disabled={isSubmitting}
            />
          )}
        />
        <div className={inputWrapper()}>
          <Controller
            control={control}
            name={SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER}
            defaultValue=""
            render={({ field }) => {
              return (
                <TextInput
                  {...field}
                  disabled={isSubmitting}
                  errors={errors}
                  label="Tracking number"
                  placeholder={placeholderText}
                  instructions={trackingNumberHintMessage}
                />
              );
            }}
          />
        </div>
        {validationMessage ? (
          <UserMessage body={validationMessage} heading="Validation check" variant="info" noIcon className={error()} />
        ) : null}
        <div className={buttonWrapper()}>
          <Button
            className={button()}
            disabled={isSubmitting || (disableButtonUntilValid && !isValid)}
            isLoading={isSubmitting}
            iconLeft={buttonIcon}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </>
  );
};

const styles = tv({
  slots: {
    base: 'flex w-full flex-col space-y-4',
    button: 'w-full',
    buttonWrapper: 'flex w-full justify-end',
    inputWrapper: 'relative flex-1',
    hintText: 'mb-0 mt-1 px-2 text-sm text-text-secondary',
    typeSelect: 'min-w-32',
    error: '!mb-5 !mt-2',
  },
  variants: {
    variant: {
      inline: {
        base: 'w-auto gap-2 sm:flex-row sm:items-end',
        button:
          'h-[--input-height] pl-base pr-base text-baseSm font-normal tracking-[0.02rem] sm:w-auto sm:pl-base sm:pr-base',
        buttonWrapper: 'mt-2 self-end sm:!mt-5 sm:ml-2 sm:w-auto sm:translate-y-px md:!mt-5',
        typeSelect: 'min-w-48',
        error: 'ml-0 w-96',
      },
    },
  },
});

export { ShipmentForm };
